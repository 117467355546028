import Vue from "vue";
import Vuex from "vuex";
import axios from '../utils/axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gameList: [],//获取所有游戏
    bannerList:[],//获取游戏banner图
    selectServe: false, //选择区服
    ServeInfo:[],//区服信息
    showServe: false, //展示区服
    gameData:{},//游戏数据
    product:{},//选取的优惠券数据
    lang:"EN",
    country:'VN'
  },
  mutations: {
    saveGameList(state, data) {
      state.gameList = data
    },
    saveBannelList(state, data) {
      state.bannerList = data
    }
  },
  actions: {
    getGameList({ commit }) {
        axios.get("/main/index").then((res) => {
          if (res.data.code === 200) {
            commit("saveGameList", res.data.data.game_list)
            commit("saveBannelList", res.data.data.banner)
          }
        });
    }
  },
  modules: {},
});
